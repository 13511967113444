import React, { Fragment } from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { Button, Modal, Alert, Radio, Spin, Icon } from 'antd';
import intl from 'react-intl-universal';
import { captureMessage, init } from '@sentry/react';
import throttle from 'lodash/throttle';


import Header from '../User/Header'
import MobileHeader from './Mobile/Header'
import NewBindWrapper from '../User/NewBindWrapper'
import getAdminRole from 'utils/getAdminRole';
import Register from '../User/Register';
import styles from './Index.less';
import styless from './Mobile.less'
import Modalo from './Modal';
import logo from 'assets/svg/logo.svg';
import { isbindAE } from './util';
import allowImg from '../../assets/newBing/xl-icon.svg';
import bulkorder from '../../assets/bulk orders.png'
import man from 'assets/man.png'
import woman from 'assets/woman.png'
import back from 'assets/priceBackground.png'
import packup from 'assets/img/packup.png'
import './index.css'
import { setOberloGuidePop } from '../../services/oberloGuilde';
import classnames from 'classnames'
import { CommonDataCollect } from 'utils/utils';
import { Put } from 'utils/request';

const logException = (res, identify) => {
  if (res?.msg) {
    captureMessage(`[pricing] ${res.msg}`);
  } else {
    captureMessage(`[pricing] ${identify}`);
  }
};
const SIN_DATA = [
  {
    title: intl.getHTML('pricing.subscription_btn11'),
    price: intl.getHTML('pricing.subscription_btn12', {
      blon: 'blon'
    }),
  },
  {
    title: intl.getHTML('pricing.subscription_btn13'),
    price: intl.getHTML('pricing.subscription_btn14', {
      blon: 'blon'
    }),
    desc: intl.getHTML('pricing.subscription_btn15', {
      blon_d: 'blon_d'
    }),
  }
]


var action = {}
@connect(({ guidance, loading, login, global, user }) => ({
  guidance,
  pageLoading: loading.effects['guidance/shopifyStatus'] || loading.effects['guidance/setProject'] || loading.effects['guidance/modificationProject'] || false,
  userInfo: login.userInfo,
  paymentPlan: user.plan,
  activateStoreLoading: loading.effects['guidance/modificationShop'],
  AEInfo: global.AEInfo,
  newUserInfo: user.newUserInfo
}))
export default class Pricing extends React.PureComponent {
  activeSelectPlan = false; // 没有 default billing store，被拦截，等待设置完成后继续

  constructor(props) {
    super(props);

    const tableData = intl.get('pricing.circulationData') || [];  //table表格tobody数据
    const pricingIncludesData = intl.get('pricing.pricingIncludesData') || [];  //优化注册率
    console.log('pricingIncludesData', pricingIncludesData);
    this.hasPayPalAccess = window.DSERS_P_P || getAdminRole();
    this.planType = '';
    this.planValue = '';

    if (!this.hasPayPalAccess) {
      const index = tableData.findIndex(i => i.type === 'sync_tk_to_paypal');
      if (index > -1) {
        tableData.splice(index, 1);
      }
    }

    this.state = {
      display: intl.get('pricing.display'),
      Basic: intl.get('pricing.HomeBasicButton'),
      Advanced: intl.get('pricing.HomeAdvancedButton'),
      Pro: intl.get('pricing.HomeProButton'),
      Standard: intl.get('pricing.HomeStandardButton'),
      NewStandard: intl.get('pricing.HomeStandardButton'),
      popping: false,
      payment: null,

      val: null,
      type: null,

      ButtonOne: true,
      ButtonTwo: true,

      period: 1,

      quantity: 0,

      popUp: false,

      types: '',

      // style: window.pricing_decline ? styles.selectPro_decline : styles.selectPro,
      style: styles.selectPro_decline,
      styleNumber: '3',

      passivity: false,

      clickOne: true,
      clickTwo: true,
      status: 0,
      tableData,
      pricingIncludesData,
      showSelectStore: false,
      selectedStoreId: undefined,
      billStoreValid: false, // 默认店铺是否有效
      showBillingStoreTip: false,
      subscribeCopywriting: false,
      subscribeVisivble: false,
      newStatus: 0,
      basicVisivble: false,
      proModalIndex: 1,
      positionFixed: false,
      bind: false,
      select: false,
      showStandard: false,  //原来的standard套餐
      showEnterprise: true,
      showNewStandard: false, //新增的低价锚点standard,
      freeTime: 14,
      //custome basic
      custom_basic: false, // type 是否为10
      show_to_basic: false, // 是否展示套餐10 --> 1
      isMobile: /iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone/i.test(navigator.userAgent), //判断是否是移动端
      showfeatures: {
        enter: false,
        pro: false,
        advance: false,
        standard: false,
        newstandard: false,
        basic: false
      },
      showListModal: true,
      timers: 0
    };
  }
  componentWillMount() {
    const { paymentPlan, userInfo, location } = this.props;
    const userID = JSON.parse(localStorage.getItem('store'))?.user_id
    this.props.dispatch({
      type: 'guidance/getFreeTime',
      payload: {
        data: {
          partner_id: '',
          sub_user_id: userID
        },
        cancelMessage: true,
        callback: d => {
          const freetime = d.data?.free_month
          if (freetime) {
            this.setState({
              freeTime: freetime * 30
            })
          } else {
            this.setState({
              freetime: 14
            })
          }

        }
      }
    });
    if (JSON.parse(localStorage.getItem('payment'))?.type == 10) {
      this.setState({
        custom_basic: true
      })
    }
  }

  timers = 0;

  componentDidMount() {
    const { paymentPlan, userInfo, location } = this.props;
    // // 组件渲染完成时埋点
    //   window.DSERS_COLLECT({
    //     module: 'after render',
    //     action: "watermark",
    //     custom_info : [{name:'watermarkType',value: window.show_watermark  ? 'showWatermark' :  'hiddenWatermark'}]
    // });
    if (location.search === '?link=bind' || location.search === '?a=2') {
      //说明是绑定第二步
      this.setState({
        bind: true
      })
    }

    setInterval(() => {
      this.setState({
        timers: this.state.timers + 1
      })
    }, 1000)

    if (location.search === '?select=1') {
      //说明是绑定第二步
      this.setState({
        select: true
      })
    }

    this.setStandard()

    this.setNewStandard()

    if (JSON.parse(localStorage.getItem('payment'))?.type == 10) {
      this.setState({
        custom_basic: true
      })
    }

    const passivity = localStorage.getItem('passivity');

    if (passivity) {
      this.setState(
        {
          passivity: true
        },
        () => {
          localStorage.removeItem('passivity');
        }
      );
    }

    if (paymentPlan && Object.keys(paymentPlan).length > 0) {
      this.displayText();
    }

    const listener = throttle(() => {
      if (this.state.bind) {
        if (document.documentElement.scrollTop >= 1200) {
          this.setState({
            positionFixed: true
          })
        } else {
          this.setState({
            positionFixed: false
          })
        }
      } else {
        if (document.documentElement.scrollTop >= 315) {
          this.setState({
            positionFixed: true
          })
        } else {
          this.setState({
            positionFixed: false
          })
        }
      }
    }, 300);

    //监听滚动条
    window.addEventListener('scroll', () => {
      if (localStorage.getItem('payment') && [5, 6].includes(JSON.parse(localStorage.getItem('payment')).type)) {
        if (this.state.bind) {
          if (document.documentElement.scrollTop >= 450) {
            this.setState({
              positionFixed: true
            })
          } else {
            this.setState({
              positionFixed: false
            })
          }
        } else {
          if (document.documentElement.scrollTop >= 450) {
            this.setState({
              positionFixed: true
            })
          } else {
            this.setState({
              positionFixed: false
            })
          }
        }
      } else {
        if (this.state.bind) {
          if (document.documentElement.scrollTop >= 380) {
            this.setState({
              positionFixed: true
            })
          } else {
            this.setState({
              positionFixed: false
            })
          }
        } else {
          if (document.documentElement.scrollTop >= 380) {
            this.setState({
              positionFixed: true
            })
          } else {
            this.setState({
              positionFixed: false
            })
          }
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { paymentPlan } = this.props;
    const { billStoreValid } = this.state;

    if (paymentPlan && Object.keys(paymentPlan).length > 0 && paymentPlan !== prevProps.paymentPlan) {
      this.displayText();
      this.setStandard();
      this.setNewStandard()
    }

    if (prevState.billStoreValid !== billStoreValid) {
      // eslint-disable-next-line
      this.setState({
        showBillingStoreTip: !billStoreValid
      });
    }
  }

  setStandard = () => {
    const { paymentPlan } = this.props
    if (paymentPlan.type === 4 && paymentPlan.status !== 3) {
      this.setState({
        showStandard: true
      })
    } else {
      this.setState({
        showStandard: false
      })
    }
  }

  setNewStandard = () => {
    const { paymentPlan } = this.props
    if (paymentPlan.type === 8 && paymentPlan.status !== 3) {
      this.setState({
        showNewStandard: true
      })
    } else {
      this.setState({
        showNewStandard: false
      })
    }
  }


  showRecommend = () => {
    this.setState({
      display: intl.get('pricing.display'),
      Basic: intl.get('pricing.HomeBasicButton'),
      Advanced: intl.get('pricing.HomeAdvancedButton'),
      Pro: intl.get('pricing.HomeProButton'),
      NewStandard: intl.get('pricing.HomeStandardButton'),
      styleNumber: '3',
      period: 1,
      style: styles.selectPro_decline
    });
  };

  displayText = () => {
    const { paymentPlan: payment } = this.props;

    if ([0, 5, 6].includes(payment.type)) {
      this.showRecommend();
      if (payment.type !== 0) {
        this.getShopifyStoreStatus(payment.default_store_id);
        this.setState({
          types: payment.type
        });
      }
    } else {

      //这个逻辑有问题
      // if([12,13,14,15,16,17].includes(payment.type)){
      //   this.setState({
      //     style:''
      //   })
      //   return
      // }

      this.getShopifyStoreStatus(payment.default_store_id);

      this.setState({
        types: payment.type,
        payment,
        period: payment.period,
        status: payment.status
      });

      if (
        payment.type == '1' &&
        payment.status != 1 &&
        payment.status != 5 &&
        payment.status != 6
      ) {
        this.setState({
          display: intl.get('pricing.Current'),
          Basic: '',
          Advanced: intl.get('pricing.Select'),
          Standard: intl.get('pricing.Select'),
          Pro: intl.get('pricing.Select'),
          NewStandard: intl.get('pricing.Select'),
          style: styles.selectBasic_decline,
          styleNumber: '1'
        });
      } else if (
        payment.type == '2' || payment.type == '7' &&
        payment.status != 1 &&
        payment.status != 5 &&
        payment.status != 6
      ) {
        this.setState({
          display: intl.get('pricing.Current'),
          Basic: intl.get('pricing.Select'),
          Standard: intl.get('pricing.Select'),
          Advanced: '',
          Pro: intl.get('pricing.Select'),
          NewStandard: intl.get('pricing.Select'),
          style: styles.selectPro_decline,
          styleNumber: '3'
        });
      } else if (
        payment.type == '3' &&
        payment.status != 1 &&
        payment.status != 5 &&
        payment.status != 6
      ) {
        this.setState({
          display: intl.get('pricing.Current'),
          Basic: intl.get('pricing.Select'),
          Advanced: intl.get('pricing.Select'),
          Standard: intl.get('pricing.Select'),
          Pro: '',
          NewStandard: intl.get('pricing.Select'),
          style: styles.selectProNew_decline,
          styleNumber: '4'
        });
      } else if (
        payment.type == '4' &&
        payment.status != 1 &&
        payment.status != 5 &&
        payment.status != 6
      ) {
        this.setState({
          display: intl.get('pricing.Current'),
          Basic: intl.get('pricing.Select'),
          Advanced: intl.get('pricing.Select'),
          Standard: '',
          Pro: intl.get('pricing.Select'),
          NewStandard: intl.get('pricing.Select'),
          style: styles.selectAdvanced_decline,
          styleNumber: '2'
        });
      } else if (
        payment.type == '8' &&
        payment.status != 1 &&
        payment.status != 5 &&
        payment.status != 6
      ) {
        this.setState({
          display: intl.get('pricing.Current'),
          Basic: intl.get('pricing.Select'),
          Advanced: intl.get('pricing.Select'),
          Standard: intl.get('pricing.Select'),
          Pro: intl.get('pricing.Select'),
          NewStandard: '',
          style: styles.selectNewStandard,
          styleNumber: '8'
        })
      } else {
        this.setState({
          display: intl.get('pricing.display'),
          Basic: intl.get('pricing.HomeBasicButton'),
          Advanced: intl.get('pricing.HomeAdvancedButton'),
          Pro: intl.get('pricing.HomeProButton'),
          NewStandard: intl.get('pricing.HomeProButton'),
          styleNumber: '3',
          style: styles.selectPro_decline
        });
      }
    }
  };

  /**
   * @param storeId 店铺id
   * @returns billStoreValid:true 
   */

  // 获取shopify店铺状态
  getShopifyStoreStatus = storeId => {
    if (!storeId) {
      return;
    }

    const stores = this.props.userInfo ? this.props.userInfo.stores : [];

    this.props.dispatch({
      type: 'guidance/shopifyStatus',
      payload: {
        data: {
          store_id: storeId
        },
        cancelMessage: true,
        callback: d => {
          if (d.data && d.data.is_available) {
            this.setState({
              billStoreValid: true
            });
          }

          if (![2000, 2010].includes(d.code)) {
            logException(d, 'guidance/shopifyStatus');
          }
        }
      }
    });
  };

  hidelogin = () => {
    this.setState({
      popUp: false
    });
  };

  /**
   * @param type 套餐类型
   */

  handlePushData = type => {
    const { period } = this.state;
    const range = period === 2 ? 'annual' : 'month';
    window.dataLayer.push({
      event: 'registerCustomEvent',
      type: 'getStartedButton',
      position: `pricing-${range}-${type}`.toLowerCase()
    });
  };

  Basic = (val, type) => {
    action['val'] = val
    action['type'] = type
    const { period } = this.state
    const { paymentPlan } = this.props;
    //判断是否展示standard逻辑
    if (this.state.showStandard) {
      if (period == 1 && !paymentPlan.id) {
        this.setState({
          basicVisivble: true
        })
        return
      }
    }


    //必须是onboding流程的
    if(!this.props?.paymentPlan?.status &&  this.props?.userInfo?.status == '10000000'){
      if (this.state.timers < 10) {
        CommonDataCollect({
          event_type: 'subscribe_cam_01',
          action: 'click_subsctibe_timers_10',
        })
      }
  
      if (this.state.timers >= 120) {
        CommonDataCollect({
          event_type: 'subscribe_cam_01',
          action: 'click_subsctibe_timers_120',
        })
      }
    }

    this.BasicChange(val, type)
  };

  getStandard = () => {

    this.dispatchChangeNew('guidance/setProject', 4, 1, {
      upgrade_to_standard: true
    })
  }

  getBasic = () => {

    this.BasicChange(action['val'], action['type'])
  }

  setAccountNouse = async ()=>{
    try {
      const res = await Put('/uaa/api/1.0/user/child_account/shopify/status',{
        data:{
          user_status:2,
          target_user:[],
          invited_user: []
          
        }
      },true)
    } catch (error) {
      console.log('fetch err',error)
    }
  }

  BasicChange = (val, type) => {
    const { paymentPlan } = this.props;
    this.handlePushData(type);
    const isGoBindAE = isbindAE(this.props.AEInfo);

    if (paymentPlan && Object.keys(paymentPlan).length > 0) {
      if (this.state.clickOne) {
        this.setState(
          {
            clickOne: false
          },
          () => {
            if (
              val == intl.get('pricing.HomeBasicButton') && this.state.types == 0
            ) {
              this.props.dispatch({
                type: 'guidance/setProject',
                payload: {
                  data: {
                    plan_type: 1,
                    plan_period: 0
                  },
                  callback: d => {
                    if (d.code === 2000) {
                      if(!this.props.newUserInfo?.user_role){
                        this.setAccountNouse()
                      }
                      // 没绑ae
                      if (isGoBindAE && (localStorage.getItem('noMigra') != 'false') && !this.props.userInfo.ae_flag) { //是迁移用户不判断ae状态 && 不属于不使用ae用户
                        this.props.dispatch(routerRedux.push('/bind/ae'));
                        return;
                      }
                      window.location.href = '/app/';
                    } else {
                      logException(d, 'guidance/setProject');
                    }
                  }
                }
              });
            } else {
              localStorage.setItem('type', type);
              localStorage.setItem('quantity', '3');
              this.props.dispatch({
                type: 'guidance/modificationProject',
                payload: {
                  data: {
                    plan_type: 1,
                    plan_period: 0
                  },
                  callback: d => {
                    if (d.code == 2000) {
                      if(!this.props.newUserInfo?.user_role){
                        this.setAccountNouse()
                      }
                      // 没绑ae
                      if (isGoBindAE && (localStorage.getItem('noMigra') != 'false') && !this.props.userInfo.ae_flag) {
                        this.props.dispatch(routerRedux.push('/bind/ae'));
                        return;
                      }
                      // this.getUserPlanRecord()
                      window.location.href = '/app/'
                    } else {
                      logException(d, 'guidance/modificationProject');
                    }
                  }
                }
              });
            }
          }
        );
      }
    } else {
      this.setState({
        popUp: true
      });
    }
  }
  /**
   * 记录Oberlo用户是否订阅过套餐
   */
  // getUserPlanRecord = () => {
  //    if (this.props.userInfo && localStorage.getItem('noMigra') == 'false'){
  //     if (!localStorage.getItem('reducePlan')){
  //           return
  //         } 
  //        setOberloGuidePop({
  //         data: { name: 'isBindPlan', value: 'true' }
  //       })
  //   }
  // }
  setMealType = () => {

    const val = this.planValue; //this.planValue:select/Start free trial
    const type = this.planType;
    this.handlePushData(type);
    const { paymentPlan } = this.props;
    const { clickTwo, types } = this.state;
    const isGoBindAE = isbindAE(this.props.AEInfo);

    if (paymentPlan && Object.keys(paymentPlan).length > 0) {
      if (clickTwo) {
        this.setState(
          {
            clickTwo: false
          },
          () => {
            if (
              (val == intl.get('pricing.HomeAdvancedButton') && types == 0) ||
              !paymentPlan.default_store_id
            ) {
              this.setState({
                val,
                type,
                popping: true
              });
            } else {
              localStorage.setItem('type', type);
              localStorage.setItem('quantity', '10');
              let plan_type = ''
              if (type === 'Advanced') {
                plan_type = 2
              } else if (type === 'Pro') {
                plan_type = 3
              } else if (type === 'Standard') {
                plan_type = 4
              } else if (type === 'NewStandard') {
                plan_type = 8
              }
              this.props.dispatch({
                type: 'guidance/modificationProject',
                payload: {
                  data: {
                    plan_type,
                    plan_period: this.state.period == 0 ? 1 : this.state.period
                  },
                  callback: d => {
                    if (d.code == 2000) {
                      this.setState({
                        subscribeCopywriting: false,
                        showSelectStore: false,
                        popping: false
                      });

                      if (d.data.confirm_url) {
                        window.location.href = d.data.confirm_url;
                        // window.open(d.data.confirm_url);
                        return;
                      }
                      // this.getUserPlanRecord()
                      // 没绑ae
                      if (isGoBindAE && (localStorage.getItem('noMigra') != 'false') && !this.props.userInfo.ae_flag) {
                        this.props.dispatch(routerRedux.push('/bind/ae'));
                        return;
                      }
                      setTimeout(() => {
                        window.location.href = '/app/'; // 首页
                      }, 500)
                    } else {
                      logException(d, 'guidance/modificationProject');
                    }
                  }
                }
              });
            }
          }
        );
      }
    } else {
      this.setState({
        popUp: true
      });
    }
  };
  /**
   * @param val 当前选择的套餐类型 year/month
   */
  // 根据年/月费进行判定渲染
  periodJudge = val => {
    const { period, payment } = this.state;

    if (period) {
      if (
        payment &&
        payment.type == '2' &&
        payment.period == val && //这里是判断购买套餐年月费是否是当前点击的年月费 
        payment.status != 1 &&
        payment.status != 5 &&
        payment.status != 6
      ) {
        this.setState({
          Basic: intl.get('pricing.Select'),
          Standard: intl.get('pricing.Select'),
          Advanced: '',
          Pro: intl.get('pricing.Select'),
          NewStandard: intl.get('pricing.Select')
        });
      } else if (
        payment &&
        payment.type == '3' &&
        payment.period == val &&
        payment.status != 1 &&
        payment.status != 5 &&
        payment.status != 6
      ) {
        this.setState({
          Basic: intl.get('pricing.Select'),
          Standard: intl.get('pricing.Select'),
          Advanced: intl.get('pricing.Select'),
          Pro: '',
          NewStandard: intl.get('pricing.Select')
        });
      } else if (
        payment &&
        payment.type == '4' &&
        payment.period == val &&
        payment.status != 1 &&
        payment.status != 5 &&
        payment.status != 6
      ) {
        this.setState({
          Basic: intl.get('pricing.Select'),
          Standard: '',
          Advanced: intl.get('pricing.Select'),
          Pro: intl.get('pricing.Select'),
          NewStandard: intl.get('pricing.Select')
        });
      } else if (
        payment &&
        payment.type == '8' &&
        payment.period == val &&
        payment.status != 1 &&
        payment.status != 5 &&
        payment.status != 6
      ) {
        this.setState({
          Basic: intl.get('pricing.Select'),
          Standard: intl.get('pricing.Select'),
          Advanced: intl.get('pricing.Select'),
          Pro: intl.get('pricing.Select'),
          NewStandard: ''
        });
      } else if (
        payment &&
        payment.type == '2' &&
        payment.period != val &&
        payment.status != 1 &&
        payment.status != 5 &&
        payment.status != 6
      ) {
        this.setState({
          Basic: intl.get('pricing.Select'),
          Advanced: intl.get('pricing.Select'),
          Standard: intl.get('pricing.Select'),
          Pro: intl.get('pricing.Select'),
          NewStandard: intl.get('pricing.Select')
        });
      } else if (
        payment &&
        payment.type == '3' &&
        payment.period != val &&
        payment.status != 1 &&
        payment.status != 5 &&
        payment.status != 6
      ) {
        this.setState({
          Basic: intl.get('pricing.Select'),
          Standard: intl.get('pricing.Select'),
          Advanced: intl.get('pricing.Select'),
          Pro: intl.get('pricing.Select'),
          NewStandard: intl.get('pricing.Select')
        });
      } else if (
        payment &&
        payment.type == '4' &&
        payment.period != val &&
        payment.status != 1 &&
        payment.status != 5 &&
        payment.status != 6
      ) {
        this.setState({
          Basic: intl.get('pricing.Select'),
          Advanced: intl.get('pricing.Select'),
          Standard: intl.get('pricing.Select'),
          Pro: intl.get('pricing.Select'),
          NewStandard: intl.get('pricing.Select')
        });
      } else if (
        payment &&
        payment.type == '8' &&
        payment.period != val &&
        payment.status != 1 &&
        payment.status != 5 &&
        payment.status != 6
      ) {
        this.setState({
          Basic: intl.get('pricing.Select'),
          Advanced: intl.get('pricing.Select'),
          Standard: intl.get('pricing.Select'),
          Pro: intl.get('pricing.Select'),
          NewStandard: intl.get('pricing.Select')
        });
      }
    }
  };

  // 切换年费月费
  period = val => {
    const { paymentPlan } = this.props;
    if (paymentPlan && Object.keys(paymentPlan).length > 0) {
      if (val == '1') {
        this.setState({
          period: 1
        });
        this.periodJudge('1');
      } else {
        this.setState({
          period: 2
        });
        this.periodJudge('2');
      }
    } else if (val == '1') {
      this.setState({
        period: 1
      });
    } else {
      this.setState({
        period: 2
      });
    }
  };

  /**
   * @returns defaultSelectedStore.id 默认可用店铺的id
   */

  getDefaultSelectedStoreId = () => {
    const { userInfo } = this.props;
    const defaultSelectedStore =
      userInfo && userInfo.stores.find(i => i.status === 1); //查找状态为1的可用店铺
    return defaultSelectedStore && defaultSelectedStore.id;
  };

  // 点击选择付费套餐
  handleSelectPlan = (val, type) => {
    const { billStoreValid } = this.state;
    const { paymentPlan, userInfo } = this.props;

    this.planType = type;
    this.planValue = val;

    if (!billStoreValid && paymentPlan && paymentPlan.type !== 0) {  //如果默认店铺不可用就显示店铺弹窗
      this.activeSelectPlan = true;
      this.setState({
        showSelectStore: true,
        selectedStoreId: this.getDefaultSelectedStoreId()
      });
      return;
    }
    
    //必须是onboding流程的
    if(!this.props?.paymentPlan?.status &&  this.props?.userInfo?.status == '10000000'){
      if (this.state.timers < 10) {
        CommonDataCollect({
          event_type: 'subscribe_cam_01',
          action: 'click_subsctibe_timers_10',
        })
      }
  
      if (this.state.timers >= 120) {
        CommonDataCollect({
          event_type: 'subscribe_cam_01',
          action: 'click_subsctibe_timers_120',
        })
      }
    }


    this.setMealType();
  };


  // 获取默认选中的店铺
  getCanSelectStore = () => {
    const stores = this.props.userInfo ? this.props.userInfo.stores : [];
    let storeId;
    let count = 0;
    stores.forEach(item => {
      if (item.status === 1) {
        storeId = item.id;
        count += 1;
      }
    });

    if (count > 1) {
      storeId = undefined;
    }

    return storeId;
  };

  // 隐藏选择店铺
  handleCancelSelectStore = () => {
    this.planValue = '';
    this.planValue = '';
    this.setState({
      showSelectStore: false,
      popping: false
    });
  };

  // 选择店铺
  handleSelectStore = e => {
    this.setState({
      selectedStoreId: e.target.value
    });
  };

  // 激活选中店铺
  handleActivateStore = () => {
    this.props.dispatch({
      type: 'guidance/modificationShop',
      payload: {
        data: {
          store_id: this.state.selectedStoreId
        },
        callback: d => {
          if (d.code === 2000 || d.code === 2010) {
            // plan 也需要更新
            this.props.dispatch({
              type: 'user/getProject',
              payload: {
                callback: () => {
                  if (this.activeSelectPlan) {
                    this.activeSelectPlan = false;
                    // this.setMealType();
                  }

                }
              }
            });
            this.handleCancelSelectStore();
          } else {
            logException(d, 'guidance/modificationShop');
          }
        }
      }
    });
  };

  // 绑定更多店铺
  handleLinkMoreStore = () => {
    this.props.dispatch(routerRedux.push('/bind/link-store')); // 首页
  };

  // 隐藏支付店铺不可用提示
  handleHideBillingStoreTip = () => {
    this.setState({
      showBillingStoreTip: false
    });
  };

  // 选时选择
  handleSelectBillingStore = () => {
    this.setState({
      showSelectStore: true,
      selectedStoreId: this.getDefaultSelectedStoreId()
    });
  };

  // 获取有没有可用的店铺
  getHasAvailableStore = () => {
    const stores = this.props.userInfo ? this.props.userInfo.stores : [];
    return stores.some(store => store.status === 1);
  };

  closePopup = () => {
    this.setState({
      popping: false,
      clickTwo: true
    });
  };

  handleSupportStaff = () => {
    window.open('https://www.facebook.com/DSersOfficial/');
    // window.location.href = 'https://www.facebook.com/DSersOfficial/'
  };

  //oberlo 迁移custom basic 
  showToBasicHandler = () => {
    this.setState({
      show_to_basic: true
    })
  }
  closeToBasicHandler = () => {
    this.setState({
      show_to_basic: false
    })
  }
  toChangeBasicHandler = () => {
    this.Basic('Select', 'Basic')
  }

  dispatchChangeNew = (method, plan_type, plan_period, args = {}) => {
    const isGoBindAE = isbindAE(this.props.AEInfo);
    this.props.dispatch({
      type: method,
      payload: {
        data: {
          plan_type: plan_type,
          plan_period: plan_period,
          ...args
        },
        callback: d => {
          if (d.code == 2000) {
            if (d.data.confirm_url) {
              window.location.href = d.data.confirm_url
              // window.open(d.data.confirm_url)
              return;
            }

            // 没绑ae
            if (isGoBindAE && (localStorage.getItem('noMigra') != 'false') && !this.props.userInfo.ae_flag) {
              this.props.dispatch(routerRedux.push('/bind/ae'));
              return;
            }

            window.location.href = '/app/'; // 首页
          } else {
            captureMessage(`[pricing] ${d?.msg || 'guidance/setProject'}`);
          }
        }
      }
    });
  }

  // 1月费  2年费
  onCancelPopup = () => {
    const type = this.planType;
    let plan_type;
    if (type === 'Advanced') {
      plan_type = 2
    } else if (type === 'Pro') {
      plan_type = 3
    }

    if (!this.props.paymentPlan.change_num && !this.props.paymentPlan.id) { //change_num 套餐更改次数
      this.dispatchChangeNew('guidance/setProject', plan_type, 1)
    } else {
      this.dispatchChangeNew('guidance/modificationProject', plan_type, 1)
    }
  }

  onConfimlPopup = () => {
    const type = this.planType;
    let plan_type;
    if (type === 'Advanced') {
      plan_type = 2
    } else if (type === 'Pro') {
      plan_type = 3
    }

    //判断是不是当前套餐
    let plan = JSON.parse(localStorage.getItem('payment'))
    if (plan.type == plan_type && plan.period == 2) {
      window.location.href = '/app/';
      return
    }

    if (!this.props.paymentPlan.change_num && !this.props.paymentPlan.id) {
      this.dispatchChangeNew('guidance/setProject', plan_type, 2)
    } else {
      this.dispatchChangeNew('guidance/modificationProject', plan_type, 2)
    }
  }

  onCancelPopupVis = () => {
    this.setState({
      subscribeVisivble: false
    })
  }

  handleShowFeatures = (type) => {
    return () => {
      switch (type) {
        case 'enter':
          return this.setState({
            showfeatures: {
              ...this.state.showfeatures,
              enter: !this.state.showfeatures.enter,
            }
          })
          break
        case 'pro':
          return this.setState({
            showfeatures: {
              ...this.state.showfeatures,
              pro: !this.state.showfeatures.pro,
            }
          })
          break
        case 'advance':
          return this.setState({
            showfeatures: {
              ...this.state.showfeatures,
              advance: !this.state.showfeatures.advance,
            }
          })
          break
        case 'standard':
          return this.setState({
            showfeatures: {
              ...this.state.showfeatures,
              standard: !this.state.showfeatures.standard,
            }
          })
          break
        case 'newstandard':
          return this.setState({
            showfeatures: {
              ...this.state.showfeatures,
              newstandard: !this.state.showfeatures.newstandard,
            }
          })
          break
        case 'basic':
          return this.setState({
            showfeatures: {
              ...this.state.showfeatures,
              basic: !this.state.showfeatures.basic,
            }
          })
          break
      }
    }
  }

  handleCloseFeatures = (type) => {
    return () => {
      switch (type) {
        case 'Enterprise':
          return this.setState({
            showfeatures: {
              ...this.state.showfeatures,
              enter: false,
            }
          })
          break
        case 'Pro':
          return this.setState({
            showfeatures: {
              ...this.state.showfeatures,
              pro: false,
            }
          })
          break
        case 'AdvancedL':
          return this.setState({
            showfeatures: {
              ...this.state.showfeatures,
              advance: false,
            }
          })
          break
        case 'Standard':
          return this.setState({
            showfeatures: {
              ...this.state.showfeatures,
              standard: false,
            }
          })
          break
        case 'NewStandard':
          return this.setState({
            showfeatures: {
              ...this.state.showfeatures,
              newstandard: false,
            }
          })
          break
        case 'Basic':
          return this.setState({
            showfeatures: {
              ...this.state.showfeatures,
              basic: false,
            }
          })
          break
      }
    }
  }

  handleCloseListModal = () => {
    this.setState({
      showListModal: false
    })
  }

  PricingTheadComponents = (value) => {
    const { Basic, Standard, Advanced, Pro, period, positionFixed, showStandard, showEnterprise, NewStandard } = this.state
    return <>
      <div className={styles.theadBox} style={positionFixed ? { marginBottom: 0, backgroundColor: "#ECEFF1" } : { marginBottom: 8, backgroundColor: 'transparent' }}>
        <div className={styles.grey}>
          <div className={styles.theadBoxItemActive} style={positionFixed ? { minHeight: 215 } : null}></div>


          <div className={styles.theadBoxItem} style={positionFixed ? { minHeight: 215 } : null}>
            <div className={styles.thick}>
              {intl.get('pricing.Enterprise')}
            </div>
            <div className={styles.thickY} >
              {period == '1' || period == '0' ?
                intl.getHTML('pricing.Enmonth') : intl.getHTML('pricing.Enannually')}
            </div>
            {
              positionFixed ? null : <>

                {period == '1' || period == '0' ? null :
                  <div className={styles.year} style={['de-DE', 'es-ES'].includes(localStorage.getItem('lang')) ? { fontSize: '12px' } : null}>{intl.getHTML('pricing.EnannuallyText')}</div>
                }
                <div className={styles.TextDesc}>
                  {intl.getHTML('pricing.EnDescribe')}
                </div>
              </>
            }
            <div className={styles.Button}>
              <Button
                data="dx"
                type="primary"
                shape="round"
                style={{ backgroundColor: '#FFFFFF', color: '#ff8f00' }}
                className={styles.headButton}
              >
                <a href='mailto:subscription@dserspro.com'>Contact us</a>
              </Button>
            </div>
          </div>
          <div className={styles.theadBoxItem} style={positionFixed ? { minHeight: 215 } : null}>
            <div className={styles.thick}>
              {intl.get('pricing.Pro')}
            </div>
            <div className={styles.thickY} style={this.state.showStandard || this.state.showNewStandard ? { fontSize: '44px' } : null}>
              {period == '1' || period == '0' ?
                intl.getHTML('pricing.Prmonth') : intl.getHTML('pricing.Prannually')}
            </div>
            {
              positionFixed ? null : <>

                {period == '1' || period == '0' ? null :
                  <div className={styles.year} style={['de-DE', 'es-ES'].includes(localStorage.getItem('lang')) ? { fontSize: '12px' } : null}>{intl.getHTML('pricing.PrannuallyText_3')}</div>
                }
                <div className={styles.TextDesc}>
                  ({this.state.freeTime}
                  {intl.getHTML('pricing.ProText')})<br />{intl.getHTML('pricing.No_card_ProText')}
                </div>
              </>
            }
            {Pro ? (
              <div className={styles.Button}>
                <Button
                  data="dx"
                  onClick={() => this.handleSelectPlan(Pro, 'Pro')}
                  type="primary"
                  shape="round"
                  id={period == 1 ? 'monthSelectPro' : 'yearSelectPro'}
                  className={styles.headButton}
                  title={Pro}
                >
                  {Pro}
                </Button>
              </div>
            ) : <div className={styles.SelectIcon}><img src={require('../../assets/newBing/checked.png')}></img></div>}
          </div>
          <div className={styles.theadBoxItem} style={positionFixed ? { minHeight: 215 } : null}>
            {value == 1 && this.state.positionFixed ? null : <img className={styles.hg} src={require('../../assets/newBing/hg.svg')}></img>}
            {value == 2 ? <img className={styles.hg} src={require('../../assets/newBing/hg.svg')}></img> : null}
            <div className={styles.thick}>
              {intl.get('pricing.Advanced')}
            </div>
            <div className={styles.thickY} style={this.state.showStandard || this.state.showNewStandard ? { fontSize: '44px' } : null}>
              {period == '1' || period == '0' ? intl.getHTML('pricing.Admonth') : intl.getHTML('pricing.Adannually')}
            </div>
            {
              positionFixed ? null :
                <>
                  {period == '1' || period == '0' ? null :
                    <div className={styles.year} style={['de-DE', 'es-ES'].includes(localStorage.getItem('lang')) ? { fontSize: '12px' } : null}>{intl.getHTML('pricing.AdannuallyText_3')}</div>
                  }
                  <div className={styles.TextDesc}>
                    ({this.state.freeTime}{intl.getHTML('pricing.AdvancedText')})<br />
                    {intl.getHTML('pricing.No_card_AdvancedText')}
                  </div>
                </>
            }
            {Advanced ? (
              <div className={styles.Button}>
                <Button
                  data="dx"
                  onClick={() =>
                    this.handleSelectPlan(Advanced, 'Advanced')
                  }
                  type="primary"
                  shape="round"
                  id={period == 1 ? 'monthSelectAdvanced' : 'yearSelectAdvanced'}
                  className={styles.headButton}
                  title={Advanced}
                >
                  {Advanced}
                </Button>
              </div>
            ) : <div className={styles.SelectIcon}><img src={require('../../assets/newBing/checked.png')}></img></div>}
          </div>
          {
            this.state.showStandard ?
              <div className={styles.theadBoxItem} style={positionFixed ? { minHeight: 215 } : null}>
                <div className={styles.thick}>
                  {intl.get('pricing.Standard')}
                </div>
                <div className={styles.thickY} style={{ fontSize: '44px' }}>
                  {period == '1' || period == '0' ? intl.getHTML('pricing.Stmonth') : intl.getHTML('pricing.Stannually')}
                </div>
                {
                  positionFixed ? null :
                    <>
                      {period == '1' || period == '0' ? null :
                        <div className={styles.year} style={['de-DE', 'es-ES'].includes(localStorage.getItem('lang')) ? { fontSize: '12px' } : null}>{intl.getHTML('pricing.StannuallyText')}</div>
                      }
                      <div className={styles.TextDesc}>
                        ({this.state.freeTime}{intl.getHTML('pricing.StandardText')})<br />
                        {intl.getHTML('pricing.No_card_StandardText')}
                      </div>
                    </>
                }
                {Standard ? (
                  <div className={styles.Button}>
                    <Button
                      data="dx"
                      onClick={() =>
                        this.handleSelectPlan(Standard, 'Standard')
                      }
                      type="primary"
                      shape="round"
                      id={period == 1 ? 'monthSelectStandard' : 'yearSelectStandard'}
                      style={{ backgroundColor: '#FFFFFF', color: '#ff8f00' }}
                      className={styles.headButton}
                    >
                      {Standard}
                    </Button>
                  </div>
                ) : <div className={styles.SelectIcon}><img src={require('../../assets/newBing/checked.png')}></img></div>}
              </div>
              :
              null
          }

          {
            this.state.showNewStandard ?
              <div className={styles.theadBoxItem} style={positionFixed ? { minHeight: 215 } : null}>
                <div className={styles.thick}>
                  {intl.get('pricing.Standard')}
                </div>
                <div className={styles.thickY} style={{ fontSize: "44px" }}>
                  {period == '1' || period == '0' ? intl.getHTML('pricing.NewStmonth') : intl.getHTML('pricing.NewStannually')}
                </div>
                {
                  positionFixed ? null :
                    <>
                      {period == '1' || period == '0' ? null :
                        <div className={styles.year} style={['de-DE', 'es-ES'].includes(localStorage.getItem('lang')) ? { fontSize: '12px' } : null}>{intl.getHTML('pricing.NewStannuallyText')}</div>
                      }
                      <div className={styles.TextDesc}>
                        ({this.state.freeTime}{intl.getHTML('pricing.StandardText')})<br />
                        {intl.getHTML('pricing.No_card_StandardText')}
                      </div>
                    </>
                }
                {NewStandard ? (
                  <div className={styles.Button}>
                    <Button
                      data="dx"
                      onClick={() =>
                        this.handleSelectPlan(NewStandard, 'NewStandard')
                      }
                      type="primary"
                      shape="round"
                      id={period == 1 ? 'monthSelectStandard' : 'yearSelectStandard'}
                      style={{ backgroundColor: '#FFFFFF', color: '#ff8f00' }}
                      className={styles.headButton}
                    >
                      {NewStandard}
                    </Button>
                  </div>
                ) : <div className={styles.SelectIcon}><img src={require('../../assets/newBing/checked.png')}></img></div>}
              </div>
              :
              null
          }

          <div className={styles.theadBoxItem} style={positionFixed ? { minHeight: 215 } : null} >
            {!this.state.custom_basic ? <div className={styles.thick}>
              {intl.get('pricing.Basic')}
            </div> : <div className={styles.thick}>{intl.get('pricing.custom_basic')}</div>}
            <div className={styles.thickY} style={['de-DE'].includes(localStorage.getItem('lang')) ? { fontSize: "40px" } : null}>
              {intl.get('pricing.Free')}
            </div>
            {
              positionFixed ? null : (!this.state.custom_basic ? <div className={styles.TextDesc}>{intl.getHTML('pricing.BasicText')}</div> : <div className={styles.TextDesc1} onClick={this.showToBasicHandler}>{intl.getHTML('pricing.custom_to_basic')}</div>)
            }
            {this.state.custom_basic ? <div className={styles.SelectIcon}><img src={require('../../assets/newBing/checked.png')}></img></div> : Basic ? (
              <div className={styles.Button}>
                <Button
                  data="dx"
                  onClick={() => this.Basic(Basic, 'Basic')}
                  type="primary"
                  shape="round"
                  id={period == 1 ? 'monthSelectBasic' : 'yearSelectBasic'}
                  style={{ backgroundColor: '#FFFFFF', color: '#ff8f00' }}
                >
                  {Basic}
                </Button>
              </div>
            ) : <div className={styles.SelectIcon}><img src={require('../../assets/newBing/checked.png')}></img></div>}
          </div>

        </div>
      </div>
    </>
  }

  /* 移动端表格展示 */
  PricingMobileTbodyComponents = (tableData, type, typeText, show) => {
    return <>
      <div
        className={this.state.style}
        border="0"
        cellPadding="0"
        cellSpacing="0"
        style={show ? null : { height: '0', overflow: 'hidden' }}
      >
        <div className={styless.tableBox} >

          {tableData.map((item, index) => {

            return (
              <div className={styless.hei} key={item.Feature}>
                <div
                  className={styless.firstDiv}
                  onClick={
                    item.Feature === 'Affiliate'
                      ? this.handleSupportStaff
                      : null
                  }
                >
                  <div
                    className={`${styless.thick} ${item.tag === 'new' ? styless.new : ''
                      }`}
                  >
                    {item.Feature === 'Affiliate' ? <span className={styless.spanICon}></span> : null}

                    {item.Feature}
                  </div>
                  <div className={styless.explain}>{item.explain}</div>
                </div>


                <div className={styless.twoDiv}>
                  <div>
                    {item[type] === 'ok' ? (
                      <img src={allowImg} />
                    ) :
                      <span
                        className={styless.innerhT} dangerouslySetInnerHTML={{ __html: item[type] }}></span>
                    }
                  </div>
                  <div>{item[typeText]}</div>
                </div>
              </div>
            );
          })}
          <div className={styless.packup}><img src={packup} alt="" onClick={this.handleCloseFeatures(type)} /></div>
        </div>
      </div>
    </>
  }
  /* 移动端头部展示 */
  PricingMobileTheadComponents = (value) => {
    const { Basic, Standard, Advanced, Pro, period, positionFixed, showStandard, showEnterprise, NewStandard, tableData } = this.state


    return <>
      <div className={styless.theadBox}>
        <div className={styless.grey}>
          <div className={styless.theadBoxItem} >
            <div className={styless.thick}>
              {intl.get('pricing.Enterprise')}
            </div>
            <div className={styless.thickY} >
              {period == '1' || period == '0' ?
                intl.getHTML('pricing.Enmonth') : intl.getHTML('pricing.Enannually')}
            </div>
            {
              <>
                {period == '1' || period == '0' ? null :
                  <div className={styless.year}>{intl.getHTML('pricing.EnannuallyText')}</div>
                }
                <div className={styless.TextDesc}>
                  {intl.getHTML('pricing.EnDescribe')}
                </div>
              </>
            }
            <div className={styless.Button}>
              <Button
                data="dx"
                type="primary"
                shape="round"
                style={{ backgroundColor: '#FFFFFF', color: '#ff8f00' }}
              >
                <a href='mailto:subscription@dserspro.com'>Contact us</a>
              </Button>
              <div className={styless.show} onClick={this.handleShowFeatures('enter', true)}>
                {this.state.showfeatures.enter ? <span>-</span> : <span>+</span>} Show plan features
              </div>
            </div>
          </div>
          {this.PricingMobileTbodyComponents(tableData, 'Enterprise', 'EnterpriseText', this.state.showfeatures.enter)}
          <div className={styless.theadBoxItem} >
            <div className={styless.thick}>
              {intl.get('pricing.Pro')}
            </div>
            <div className={styless.thickY} style={this.state.showStandard || this.state.showNewStandard ? { fontSize: '44px' } : null}>
              {period == '1' || period == '0' ?
                intl.getHTML('pricing.Prmonth') : intl.getHTML('pricing.Prannually')}
            </div>
            {
              <>
                {period == '1' || period == '0' ? null :
                  <div className={styless.year}>{intl.getHTML('pricing.PrannuallyText_3')}</div>
                }
                <div className={styless.TextDesc}>
                  ({this.state.freeTime}
                  {intl.getHTML('pricing.ProText')})<br />{intl.getHTML('pricing.No_card_ProText')}
                </div>
              </>
            }
            {Pro ? (
              <div className={styless.Button}>
                <Button
                  data="dx"
                  onClick={() => this.handleSelectPlan(Pro, 'Pro')}
                  type="primary"
                  shape="round"
                  id={period == 1 ? 'monthSelectPro' : 'yearSelectPro'}
                >
                  {Pro}
                </Button>
              </div>
            ) : <div className={styless.SelectIcon}><img src={require('../../assets/newBing/checked.png')}></img></div>}
            <div className={styless.show} onClick={this.handleShowFeatures('pro', true)}>
              {this.state.showfeatures.pro ? <span>-</span> : <span>+</span>} Show plan features
            </div>
          </div>
          {this.PricingMobileTbodyComponents(tableData, 'Pro', 'ProText', this.state.showfeatures.pro)}
          <div className={styless.theadBoxItem} >
            <div className={styless.thick}>
              {intl.get('pricing.Advanced')}
            </div>
            <div className={styless.thickY} style={this.state.showStandard || this.state.showNewStandard ? { fontSize: '44px' } : null}>
              {period == '1' || period == '0' ? intl.getHTML('pricing.Admonth') : intl.getHTML('pricing.Adannually')}
            </div>
            {
              <>
                {period == '1' || period == '0' ? null :
                  <div className={styless.year}>{intl.getHTML('pricing.AdannuallyText_3')}</div>
                }
                <div className={styless.TextDesc}>
                  ({this.state.freeTime}{intl.getHTML('pricing.AdvancedText')})<br />
                  {intl.getHTML('pricing.No_card_AdvancedText')}
                </div>
              </>
            }
            {Advanced ? (
              <div className={styless.Button}>
                <Button
                  data="dx"
                  onClick={() =>
                    this.handleSelectPlan(Advanced, 'Advanced')
                  }
                  type="primary"
                  shape="round"
                  id={period == 1 ? 'monthSelectAdvanced' : 'yearSelectAdvanced'}
                >
                  {Advanced}
                </Button>
              </div>
            ) : <div className={styless.SelectIcon}><img src={require('../../assets/newBing/checked.png')}></img></div>}
            <div className={styless.show} onClick={this.handleShowFeatures('advance', true)}>
              {this.state.showfeatures.advance ? <span>-</span> : <span>+</span>} Show plan features
            </div>
          </div>
          {this.PricingMobileTbodyComponents(tableData, 'AdvancedL', 'AdvancedText', this.state.showfeatures.advance)}

          {
            this.state.showStandard ?
              <div className={styless.theadBoxItem} >
                <div className={styless.thick}>
                  {intl.get('pricing.Standard')}
                </div>
                <div className={styless.thickY} style={{ fontSize: '44px' }}>
                  {period == '1' || period == '0' ? intl.getHTML('pricing.Stmonth') : intl.getHTML('pricing.Stannually')}
                </div>
                {
                  <>
                    {period == '1' || period == '0' ? null :
                      <div className={styless.year}>{intl.getHTML('pricing.StannuallyText')}</div>
                    }
                    <div className={styless.TextDesc}>
                      ({this.state.freeTime}{intl.getHTML('pricing.StandardText')})<br />
                      {intl.getHTML('pricing.No_card_StandardText')}
                    </div>
                  </>
                }
                {Standard ? (
                  <div className={styless.Button}>
                    <Button
                      data="dx"
                      onClick={() =>
                        this.handleSelectPlan(Standard, 'Standard')
                      }
                      type="primary"
                      shape="round"
                      id={period == 1 ? 'monthSelectStandard' : 'yearSelectStandard'}
                      style={{ backgroundColor: '#FFFFFF', color: '#ff8f00' }}
                    >
                      {Standard}
                    </Button>
                  </div>
                ) : <div className={styless.SelectIcon}><img src={require('../../assets/newBing/checked.png')}></img></div>}
                <div className={styless.show} onClick={this.handleShowFeatures('standard', true)}>
                  {this.state.showfeatures.standard ? <span>-</span> : <span>+</span>} Show plan features
                </div>
              </div>
              :
              null
          }
          {this.state.showStandard ? this.PricingMobileTbodyComponents(tableData, 'Standard', 'StandardText', this.state.showfeatures.standard) : null}

          {
            this.state.showNewStandard ?
              <div className={styless.theadBoxItem} >
                <div className={styless.thick}>
                  {intl.get('pricing.Standard')}
                </div>
                <div className={styless.thickY} style={{ fontSize: "44px" }}>
                  {period == '1' || period == '0' ? intl.getHTML('pricing.NewStmonth') : intl.getHTML('pricing.NewStannually')}
                </div>
                {
                  <>
                    {period == '1' || period == '0' ? null :
                      <div className={styless.year}>{intl.getHTML('pricing.NewStannuallyText')}</div>
                    }
                    <div className={styless.TextDesc}>
                      ({this.state.freeTime}{intl.getHTML('pricing.StandardText')})<br />
                      {intl.getHTML('pricing.No_card_StandardText')}
                    </div>
                  </>
                }
                {NewStandard ? (
                  <div className={styless.Button}>
                    <Button
                      data="dx"
                      onClick={() =>
                        this.handleSelectPlan(NewStandard, 'NewStandard')
                      }
                      type="primary"
                      shape="round"
                      id={period == 1 ? 'monthSelectStandard' : 'yearSelectStandard'}
                      style={{ backgroundColor: '#FFFFFF', color: '#ff8f00' }}
                    >
                      {NewStandard}
                    </Button>
                  </div>
                ) : <div className={styless.SelectIcon}><img src={require('../../assets/newBing/checked.png')}></img></div>}
                <div className={styless.show} onClick={this.handleShowFeatures('newstandard', true)}>
                  {this.state.showfeatures.newstandard ? <span>-</span> : <span>+</span>} Show plan features
                </div>
              </div>
              :
              null
          }
          {this.state.showNewStandard ? this.PricingMobileTbodyComponents(tableData, 'NewStandard', 'NewStandardText', this.state.showfeatures.newstandard) : null}

          <div className={styless.theadBoxItem} >
            <div className={styless.thick}>
              {intl.get('pricing.Basic')}
            </div>
            <div className={styless.thickY}>
              {intl.get('pricing.Free')}
            </div>
            <div className={styless.TextDesc}>{intl.getHTML('pricing.BasicText')}</div>
            {Basic ? (
              <div className={styless.Button}>
                <Button
                  data="dx"
                  onClick={() => this.Basic(Basic, 'Basic')}
                  type="primary"
                  shape="round"
                  id={period == 1 ? 'monthSelectBasic' : 'yearSelectBasic'}
                  style={{ backgroundColor: '#FFFFFF', color: '#ff8f00' }}
                >
                  {Basic}
                </Button>
              </div>
            ) : <div className={styless.SelectIcon}><img src={require('../../assets/newBing/checked.png')}></img></div>}
            <div className={styless.show} onClick={this.handleShowFeatures('basic', true)}>
              {this.state.showfeatures.basic ? <span>-</span> : <span>+</span>} Show plan features
            </div>
          </div>
          {this.PricingMobileTbodyComponents(tableData, 'Basic', 'BasicText', this.state.showfeatures.basic)}
        </div>
      </div>
    </>
  }

  render() {
    const { activateStoreLoading, userInfo, paymentPlan, pageLoading } = this.props;
    const {
      display,
      Basic,
      Advanced,
      Standard,
      Pro,
      popping,
      val,
      type,
      types,
      period,
      style,
      styleNumber,
      tableData,
      popUp,
      passivity,
      status,
      showSelectStore,
      selectedStoreId,
      showBillingStoreTip,
      subscribeCopywriting,
      subscribeVisivble,
      newStatus,
      basicVisivble,
      pricingIncludesData,
      positionFixed,
      bind,
      select,
      showStandard,
      showEnterprise,
      showNewStandard,
      isMobile,
      showListModal
    } = this.state;
    const stores = userInfo ? userInfo.stores : [];
    // business及enterprise套餐为定制套餐 不能在此页面选择 但需要提示用户当前套餐
    let enterprisePlan = '';
    if (paymentPlan && [5, 6].includes(paymentPlan.type)) {
      const periodMap = { 1: 'Monthly', 2: 'Annually' };
      const planMap = { 5: 'Business', 6: 'Enterprise' };
      const periodText = intl.get(`pricing.${periodMap[paymentPlan.period]}`);
      const planText = intl.get(`pricing.${planMap[paymentPlan.type]}`);
      enterprisePlan = `${planText} / ${periodText}`;
    }
    return (
      <>
        {
          isMobile ?
            <div>
              <MobileHeader></MobileHeader>
              <Spin spinning={pageLoading}>
                <div>
                  {passivity ? (
                    <div className={styles.alertWrap2}>
                      <i class="material-icons notranslate">info_outline</i> {intl.getHTML('pricing.AlertText')}
                    </div>
                  ) : null}
                  {showBillingStoreTip ? (
                    <Alert
                      closable
                      className={styles.Alert}
                      onClose={this.handleHideBillingStoreTip}
                      message={
                        <p className={styles.showBillingStoreTip}>
                          Your current billing store is not available. Please <span onClick={this.handleSelectBillingStore} type="link">Reselect</span> a billing store to use DSers paid plan.
                        </p>
                      }
                      type="error"
                    />
                  ) : null}
                </div>

                <div className={styless.pricingWrap}>
                  {
                    !bind ? <>
                      <h3 className={styless.pricingTitle} style={{ paddingTop: 64 }}>{intl.get('pricing.title')} <span className={styless.selectplan}>{intl.get('pricing.tipSelectPlan')}</span> </h3>
                      <p className={styless.pricingTip}></p>
                    </> : <div
                      className={styless.pBox}
                    >
                      <h3 className={styless.pricingTitleX} style={{ paddingTop: 64, marginBottom: '8px' }}>Step 2. Choose a plan for DSers</h3>
                      <p className={styless.pricingTipX}>Is monthly payment suitable for you or annually payment suitable for you?</p>
                    </div>
                  }

                  <div className={styless.center}>
                    <div className={styless.button} style={bind ? { textAlign: 'left' } : null}>
                      <div
                        onClick={() => this.period(1)}
                        className={
                          period == '0' || period == '1'
                            ? styless.buttonColorTrigger
                            : styless.buttonColor
                        }
                      >
                        {intl.get('pricing.Monthly')}
                      </div>
                      <div
                        onClick={() => this.period(2)}
                        className={
                          period == '2' ? styless.buttonColorTrigger : styless.buttonColor
                        }
                      >
                        {intl.get('pricing.Annually')}
                      </div>
                    </div>
                    {paymentPlan && [5, 6].includes(paymentPlan.type) ? (
                      <p className={styless.tipSelectPlan}>
                        {intl.get('pricing.enterprise_current_plan', {
                          plan: enterprisePlan
                        })}
                      </p>
                    ) : null}
                    {subscribeCopywriting ? (
                      <Alert
                        style={{ marginBottom: 15 }}
                        className={styless.Alert}
                        message={
                          <p className={styless.showBillingStoreTip}>
                            {intl.get('pricing.subscribe_tios')}
                          </p>
                        }
                        type="error"
                      />
                    ) : null}
                    <div className={styless.tables}>

                      {this.PricingMobileTheadComponents(1)}

                      <div className={styless.includes}>
                        <h1>{intl.getHTML('pricing.pricingIncludesTitle')}</h1>
                        <div className={styless.includes_list} style={showListModal ? { height: '362px' } : null}>
                          {
                            pricingIncludesData?.map((item, index) => {
                              return <div key={index} className={styless.includes_item}>
                                {item.title === "Bulk Orders" ? <img src={bulkorder} alt="" style={{ float: 'left', marginTop: '10px' }} /> : null}
                                <div className={styless.t}>{item.title}</div>
                                <div className={styless.d}>{item.desc}</div>
                              </div>
                            })
                          }
                          {
                            !showListModal && <div className={styless.packdown}>
                              <img src={packup} alt="" onClick={() => {
                                this.setState({
                                  showListModal: true
                                })
                              }} />
                            </div>
                          }
                        </div>
                        {
                          showListModal &&
                          <div className={styless.modal}>
                            <img src={packup} alt="" onClick={this.handleCloseListModal} />
                          </div>
                        }

                      </div>
                    </div>
                  </div>
                </div>

                <Modal
                  visible={popUp}
                  maskClosable={false}
                  footer={null}
                  onCancel={this.hidelogin}
                  className={styless.LogReg}
                  wrapClassName="loginDialog"
                  centered
                  width={488}
                >
                  <Register hidelogin={this.hidelogin} />
                </Modal>

                <Modal
                  width={583}
                  visible={this.state.subscribeVisivble}
                  closable={false}
                  footer={null}
                  wrapClassName="webp"
                >
                  <div className="pop_box">
                    <p className="pop_title">{intl.getHTML('pricing.subscription_btn9')}</p>
                    <p className="pop_desc">{intl.getHTML('pricing.subscription_btn10')}</p>
                    <div className="pop_select_box">
                      {
                        SIN_DATA.map((item, index) => {
                          return <div className={index == this.state.proModalIndex ? 'item active' : 'item'} onClick={() => {
                            this.setState({
                              proModalIndex: index
                            })
                          }}>
                            <div className="select">{item.title}</div>
                            <div className="price">{item.price}</div>
                            <div className="price_y">{item?.desc}</div>
                            <div className="days">{intl.getHTML('pricing.subscription_btn16')}</div>
                          </div>
                        })
                      }
                    </div>
                    <div className="select_box">
                      <a className="select_btn" id={!this.state.proModalIndex ? 'select_btn_mo' : 'select_btn_year'} onClick={() => {
                        if (!this.state.proModalIndex) {
                          this.onCancelPopup()
                        } else {
                          this.onConfimlPopup()
                        }
                      }}>{intl.getHTML('pricing.subscription_btn17')}</a>
                    </div>
                  </div>
                </Modal>

                <Modal
                  width={689}
                  visible={this.state.basicVisivble}
                  closable={true}
                  footer={null}
                  wrapClassName="basicWebp"
                  onCancel={() => {
                    this.setState({
                      basicVisivble: false
                    })
                  }}
                >
                  <div className="basicBox">
                    <p className="first_title">{intl.getHTML('pricing.subscription_btn3')}</p>
                    <h1>{intl.getHTML('pricing.subscription_btn4')}</h1>
                    <p className="today">{intl.getHTML('pricing.subscription_btn5')}</p>
                    <p className="content">{intl.getHTML('pricing.subscription_btn6', {
                      titlesd: 'titlesd'
                    })}</p>
                    <a className="more" id="basicYes" onClick={this.getStandard}>{intl.getHTML('pricing.subscription_btn7')}</a>
                    <a className="no" id="basicNo" onClick={this.getBasic}>{intl.getHTML('pricing.subscription_btn8')}</a>
                  </div>
                </Modal>

                {popping ? (
                  <Modalo
                    val={val}
                    type={type}
                    period={period}
                    types={types}
                    status={status}
                    payment={paymentPlan}
                    closePopup={this.closePopup}
                  />
                ) : null}

                <Modal
                  visible={showSelectStore}
                  footer={
                    <div className={styless.selectStoreFooter}>
                      {!this.getHasAvailableStore() ? (
                        <Button type="primary" data="dx" onClick={this.handleLinkMoreStore}>
                          Link to more store
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          data="dx"
                          loading={activateStoreLoading}
                          disabled={!selectedStoreId}
                          onClick={this.handleActivateStore}
                        >
                          Activate
                        </Button>
                      )}
                    </div>
                  }
                  width={560}
                  maskClosable={false}
                  title="Please select the store you will use to be billed for DSers"
                  onCancel={this.handleCancelSelectStore}
                >
                  <Radio.Group
                    onChange={this.handleSelectStore}
                    value={selectedStoreId}
                  >
                    {stores.map(item => {
                      if (item.status !== 1) {
                        return null;
                      }
                      return (
                        <Radio
                          value={item.id}
                          key={item.id}
                          className={styless.storeRadio}
                        >
                          {`${item.shop_name}.myshopify.com`}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                  {!this.getHasAvailableStore() ? (
                    <p className={styless.noValidStore}>No valid store linked to your account.</p>
                  ) : null}

                </Modal>
              </Spin>
            </div>
            :
            <div>
              {bind ? <NewBindWrapper bind="pricing" /> : null}
              {/* 是否展示头部 */}
              {select ? <Header /> : null}
              <Spin spinning={pageLoading}>
                <div>
                  {passivity ? (
                    <div className={styles.alertWrap2}>
                      <i class="material-icons notranslate">info_outline</i> {intl.getHTML('pricing.AlertText')}
                    </div>
                  ) : null}
                  {showBillingStoreTip ? (
                    <Alert
                      closable
                      className={styles.Alert}
                      onClose={this.handleHideBillingStoreTip}
                      message={
                        <p className={styles.showBillingStoreTip}>
                          Your current billing store is not available. Please <span onClick={this.handleSelectBillingStore} type="link">Reselect</span> a billing store to use DSers paid plan.
                        </p>
                      }
                      type="error"
                    />
                  ) : null}
                </div>

                <div className={styles.pricingWrap} style={{ backgroundImage: `url(${back})`, backgroundPositionY: '-280px', backgroundPositionX: '-169px' }}>
                  {
                    !bind ? <>
                      <h3 className={styles.pricingTitle} style={{ paddingTop: 64 }}> <span className={styles.selectplan}>{intl.get('pricing.tipSelectPlan')}</span>{intl.get('pricing.title')} </h3>
                      <p className={styles.pricingTip}></p>
                    </> : <>
                      <h3 className={styles.pricingTitle} style={{ paddingTop: 64 }}><span className={styles.selectplan}>Step 2. {intl.get('pricing.tipSelectPlan')}</span> {intl.get('pricing.title')} </h3>
                      <p className={styles.pricingTip}></p>
                    </>
                  }

                  <div className={styles.center}>
                    <div className={styles.button} style={bind ? { textAlign: 'left' } : null}>
                      <div
                        onClick={() => this.period(1)}
                        className={
                          period == '0' || period == '1'
                            ? styles.buttonColorTrigger
                            : styles.buttonColor
                        }
                      >
                        {intl.get('pricing.Monthly')}
                      </div>
                      <div
                        onClick={() => this.period(2)}
                        className={
                          period == '2' ? styles.buttonColorTrigger : styles.buttonColor
                        }
                      >
                        {intl.get('pricing.Annually')}
                      </div>
                    </div>
                    {paymentPlan && [5, 6].includes(paymentPlan.type) ? (
                      <p className={styles.tipSelectPlan}>
                        {intl.get('pricing.enterprise_current_plan', {
                          plan: enterprisePlan
                        })}
                      </p>
                    ) : null}
                    {subscribeCopywriting ? (
                      <Alert
                        style={{ marginBottom: 15 }}
                        className={styles.Alert}
                        message={
                          <p className={styles.showBillingStoreTip}>
                            {intl.get('pricing.subscribe_tios')}
                          </p>
                        }
                        type="error"
                      />
                    ) : null}
                    <div className={styles.tables}>
                      {
                        positionFixed ? <div
                          style={showStandard || showNewStandard ? {
                            position: 'fixed',
                            top: '50px',
                            zIndex: 9,
                            width: '1140px'
                          } : {
                            position: 'fixed',
                            top: '50px',
                            zIndex: 9
                          }}
                          border="0"
                          cellPadding="0"
                          cellSpacing="0"
                        >
                          {this.PricingTheadComponents(2)}
                          <div className={styles.h_box}></div>
                          <div className={styles.t_box}></div>
                        </div> : null
                      }

                      {this.PricingTheadComponents(1)}
                      <div
                        className={style}
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <div className={showStandard || showNewStandard ? styles.tableBox2 : styles.tableBox} >

                          {tableData.map((item, index) => {

                            return (
                              <div className={styles.hei} key={item.Feature}>


                                <div
                                  className={styles.firstDiv}
                                  onClick={
                                    item.Feature === 'Affiliate'
                                      ? this.handleSupportStaff
                                      : null
                                  }
                                >
                                  <div
                                    className={`${styles.thick} ${item.tag === 'new' ? styles.new : ''
                                      }`}
                                  >
                                    {item.Feature === 'Affiliate' ? <span className={styles.spanICon}></span> : null}

                                    {item.Feature}
                                  </div>
                                  <div className={styles.explain}>{item.explain}</div>
                                </div>


                                <div className={styles.Enterprise}>
                                  <div>
                                    {item.Enterprise === 'ok' ? (
                                      <img src={allowImg} />
                                    ) :
                                      <span
                                        style={showStandard || showNewStandard ? { display: 'inline-block' } : { display: 'inline-block', width: 200 }}
                                        className={styles.innerhT} dangerouslySetInnerHTML={{ __html: item.Enterprise }}></span>
                                    }
                                  </div>
                                  <div>{item.EnterpriseText}</div>
                                </div>


                                <div className={styles.fiveDiv}>
                                  <div>
                                    {item.Pro === 'ok' ? (
                                      <img src={allowImg} />
                                    ) :
                                      <span
                                        style={showStandard || showNewStandard ? { display: 'inline-block' } : { display: 'inline-block', width: 200 }}
                                        className={styles.innerhT} dangerouslySetInnerHTML={{ __html: item.Pro }}></span>
                                    }
                                  </div>
                                  <div>{item.ProText}</div>
                                </div>
                                <div className={styles.fourDiv}>
                                  <div>
                                    {item.AdvancedL === 'ok' ? (
                                      <img src={allowImg} />
                                    ) :
                                      <span className={styles.innerhT} dangerouslySetInnerHTML={{ __html: item.AdvancedL }}></span>
                                    }
                                  </div>
                                  <div>{item.AdvancedText}</div>
                                </div>

                                {
                                  showStandard ?
                                    <div className={styles.threeDiv}>
                                      <div>
                                        {item.Standard === 'ok' ? (
                                          <img src={allowImg} />
                                        ) :
                                          <span className={styles.innerhT} dangerouslySetInnerHTML={{ __html: item.Standard }}></span>
                                        }
                                      </div>
                                      <div>{item.StandardText}</div>
                                    </div>
                                    :
                                    null
                                }
                                {
                                  showNewStandard ?
                                    <div className={styles.sixDiv}>
                                      <div>
                                        {item.Basic === 'ok' ? (
                                          <img src={allowImg} />
                                        ) : <span className={styles.innerhT} dangerouslySetInnerHTML={{ __html: item.NewStandard }}></span>}
                                      </div>
                                      <div>{item.NewStandardText}</div>
                                    </div>
                                    :
                                    null
                                }
                                {this.state.custom_basic ? <div className={styles.twoDiv}>
                                  <div>
                                    {item.Basic === 'ok' ? (
                                      <img src={allowImg} />
                                    ) : <span className={styles.innerhT} dangerouslySetInnerHTML={{ __html: item.CustomBasic }}></span>}
                                  </div>
                                  <div>{item.BasicText}</div>
                                </div> :
                                  <div className={styles.twoDiv}>
                                    <div>
                                      {item.Basic === 'ok' ? (
                                        <img src={allowImg} />
                                      ) : <span className={styles.innerhT} dangerouslySetInnerHTML={{ __html: item.Basic }}></span>}
                                    </div>
                                    <div>{item.BasicText}</div>
                                  </div>}

                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* 优化注册率 暂时不要 */}
                      {/* <div className={styles.includes}>
                  <h1>{intl.getHTML('pricing.pricingIncludesTitle')}</h1>
                  <div className={styles.includes_list}>
                    {
                      pricingIncludesData?.map((item,index) => {
                        return <div key={index} className={styles.includes_item}>
                          <div className={styles.t}>{item.title}</div>
                          <div className={styles.d}>{item.desc}</div>
                        </div>
                      })
                    }
                  </div>
                </div> */}
                      <div className={styles.includes}>
                        <h1>{intl.getHTML('pricing.pricingIncludesTitle')}</h1>
                        <div className={styles.includes_list}>
                          {
                            pricingIncludesData?.map((item, index) => {
                              return <div key={index} className={styles.includes_item}>
                                {item.title === "Bulk Orders" ? <img src={bulkorder} alt="" style={{ float: 'left', marginTop: '10px' }} /> : null}
                                <div className={styles.t}>{item.title}</div>
                                <div className={styles.d}>{item.desc}</div>
                              </div>
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Modal
                  visible={popUp}
                  maskClosable={false}
                  footer={null}
                  onCancel={this.hidelogin}
                  className={styles.LogReg}
                  wrapClassName="loginDialog"
                  centered
                  width={488}
                >
                  <Register hidelogin={this.hidelogin} />
                </Modal>

                <Modal
                  visible={this.state.show_to_basic}
                  maskClosable={true}
                  // footer={null}
                  onCancel={this.closeToBasicHandler}
                  onOk={this.toChangeBasicHandler}
                  okText={intl.getHTML('pricing.tobasictext')}
                  cancelText={intl.getHTML('pricing.oCancel')}
                  className={styles.toBasicContainer}
                  wrapClassName="loginDialog"
                  centered
                  width={488}
                >
                  <div className={styles.toBasicContainer1}>
                    <p className={styles.note}>{intl.getHTML('pricing.tobaiscnote')}</p>
                    <p className={styles.des}>{intl.getHTML('pricing.tobaiscdes')}</p>
                  </div>
                </Modal>

                <Modal
                  width={583}
                  visible={this.state.subscribeVisivble}
                  closable={false}
                  footer={null}
                  wrapClassName="webp"
                >
                  <div className="pop_box">
                    <p className="pop_title">{intl.getHTML('pricing.subscription_btn9')}</p>
                    <p className="pop_desc">{intl.getHTML('pricing.subscription_btn10')}</p>
                    <div className="pop_select_box">
                      {
                        SIN_DATA.map((item, index) => {
                          return <div className={index == this.state.proModalIndex ? 'item active' : 'item'} onClick={() => {
                            this.setState({
                              proModalIndex: index
                            })
                          }}>
                            <div className="select">{item.title}</div>
                            <div className="price">{item.price}</div>
                            <div className="price_y">{item?.desc}</div>
                            <div className="days">{intl.getHTML('pricing.subscription_btn16')}</div>
                          </div>
                        })
                      }
                    </div>
                    <div className="select_box">
                      <a className="select_btn" id={!this.state.proModalIndex ? 'select_btn_mo' : 'select_btn_year'} onClick={() => {
                        //    const custom_info = [
                        //     { name: 'button type', value: 'pro' },  
                        //     { name: 'fee type', value: !this.state.proModalIndex ? 'month' : 'year'},  
                        //     { name: "price order", value: window.pricing_decline ? 'decline' : 'rise'}, 
                        //     // { name: 'price text', value: window.pricing_number_optimize? 'optimize': 'regular'}
                        //     ];
                        //   window.DSERS_COLLECT({
                        //     module: 'pricing table',
                        //     action: 'click pricing button', 
                        //     custom_info
                        // })
                        if (!this.state.proModalIndex) {
                          this.onCancelPopup()
                        } else {
                          this.onConfimlPopup()
                        }
                      }}>{intl.getHTML('pricing.subscription_btn17')}</a>
                    </div>
                  </div>
                </Modal>

                <Modal
                  width={689}
                  visible={this.state.basicVisivble}
                  closable={true}
                  footer={null}
                  wrapClassName="basicWebp"
                  onCancel={() => {
                    this.setState({
                      basicVisivble: false
                    })
                  }}
                >
                  <div className="basicBox">
                    <p className="first_title">{intl.getHTML('pricing.subscription_btn3')}</p>
                    <h1>{intl.getHTML('pricing.subscription_btn4')}</h1>
                    <p className="today">{intl.getHTML('pricing.subscription_btn5')}</p>
                    <p className="content">{intl.getHTML('pricing.subscription_btn6', {
                      titlesd: 'titlesd'
                    })}</p>
                    <a className="more" id="basicYes" onClick={this.getStandard}>{intl.getHTML('pricing.subscription_btn7')}</a>
                    <a className="no" id="basicNo" onClick={this.getBasic}>{intl.getHTML('pricing.subscription_btn8')}</a>
                  </div>
                </Modal>

                {popping ? (
                  <Modalo
                    val={val}
                    type={type}
                    period={period}
                    types={types}
                    status={status}
                    payment={paymentPlan}
                    closePopup={this.closePopup}
                  />
                ) : null}

                <Modal
                  visible={showSelectStore}
                  footer={
                    <div className={styles.selectStoreFooter}>
                      {!this.getHasAvailableStore() ? (
                        <Button type="primary" data="dx" onClick={this.handleLinkMoreStore}>
                          Link to more store
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          data="dx"
                          loading={activateStoreLoading}
                          disabled={!selectedStoreId}
                          onClick={this.handleActivateStore}
                        >
                          Activate
                        </Button>
                      )}
                    </div>
                  }
                  width={560}
                  maskClosable={false}
                  title="Please select the store you will use to be billed for DSers"
                  onCancel={this.handleCancelSelectStore}
                >
                  <Radio.Group
                    onChange={this.handleSelectStore}
                    value={selectedStoreId}
                  >
                    {stores.map(item => {
                      if (item.status !== 1) {
                        return null;
                      }
                      return (
                        <Radio
                          value={item.id}
                          key={item.id}
                          className={styles.storeRadio}
                        >
                          {`${item.shop_name}.myshopify.com`}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                  {!this.getHasAvailableStore() ? (
                    <p className={styles.noValidStore}>No valid store linked to your account.</p>
                  ) : null}

                </Modal>
              </Spin>
            </div>
        }
      </>
    );
  }
}