import React from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { Modal, Button, Radio } from 'antd';
import debounce from 'lodash/debounce';
import { captureMessage } from '@sentry/react';
import FixedModal from '../../components/FixedAdvanced/FixedModal'

import intl from 'react-intl-universal';
import { isbindAE } from './util';

@connect(({ guidance, loading, login, global }) => ({
  guidance,
  userInfo: login.userInfo,
  loading: loading.models.guidance,
  AEInfo: global.AEInfo
}))
export default class Pricing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      stores: [],
      buttonDisabled: true,
      isTrue: true,
      subscribeVisivble:false,
      flag:true,
      refresh: false
    };
  }

  componentDidMount() {
    const { stores = [] } = this.props.userInfo;
    const defaultStoreId = this.getCanSelectStore(stores);
    this.setState({
      stores,
      value: defaultStoreId,
      buttonDisabled: !defaultStoreId
    });
    if (defaultStoreId) {
      localStorage.setItem('storeId', defaultStoreId);
    }
  }

  componentDidUpdate(prevProps) {
    const { userInfo } = this.props;

    if (userInfo && userInfo !== prevProps.userInfo) {
      this.setState({
        stores: userInfo.stores
      });
    }
  }

  onChoice = e => {
    localStorage.setItem('storeId', e.target.value);
    this.setState({
      value: e.target.value,
      buttonDisabled: false
    });
  };

  // 获取默认选中的店铺
  getCanSelectStore = stores => {
    let storeId;
    let count = 0;

    stores.forEach(item => {
      if (item.status === 1) {
        storeId = item.id;
        count += 1;
      }
    });

    if (count > 1) {
      storeId = undefined;
    }

    return storeId;
  };

  handleConfirm = () => {
    const { payment } = this.props;
    const { value } = this.state;
    if (payment.type !== 0) {
      this.props.dispatch({
        type: 'guidance/modificationShop',
        payload: {
          data: {
            store_id: value
          },
          callback: d => {
            if (d.code === 2000 || d.code === 2010) {
              this.dispatchChange();
            } else {
              captureMessage(`[pricing] ${d?.msg || 'guidance/modificationShop'}`);
            }
          }
        }
      });
      return;
    }
    this.dispatchChange();
  };

  dispatchChange = () => {
    const { val, type, status, payment } = this.props;
    const isGoBindAE = isbindAE(this.props.AEInfo);

    if (this.state.isTrue) {
      this.setState(
        {
          isTrue: false
        },
        () => {
          if (payment.type === 0) {
            let plan_type = ''
            if (type === 'Advanced') {
              plan_type = 2
            } else if (type === 'Pro') {
              plan_type = 3
            } else if (type === 'Standard') {
              plan_type = 4
            }else if (type === 'NewStandard'){
              plan_type = 8
            }
            this.props.dispatch({
              type: 'guidance/setProject',
              payload: {
                data: {
                  plan_type,
                  plan_period: this.props.period == 0 ? 1 : this.props.period
                },
                callback: d => {
                  if (d.code == 2000) {
                    if (d.data.confirm_url) {
                      window.open(d.data.confirm_url);
                      this.setState({
                        refresh: true
                      })
                      return;
                    }

                    // 没绑ae
                    if (isGoBindAE && (localStorage.getItem('noMigra') != 'false') && !this.props.userInfo.ae_flag) {
                      this.props.dispatch(routerRedux.push('/bind/ae'));
                      return;
                    }

                    self.location.href = '/app/'; // 首页
                  } else {
                    captureMessage(`[pricing] ${d?.msg || 'guidance/setProject'}`);
                  }
                }
              }
            });
          } else {
            let plan_type = ''
            if (type === 'Advanced') {
              plan_type = 2
            } else if (type === 'Pro') {
              plan_type = 3
            } else if (type === 'Standard') {
              plan_type = 4
            }else if (type === 'NewStandard'){
              plan_type = 8
            }
            this.props.dispatch({
              type: 'guidance/modificationProject',
              payload: {
                data: {
                  plan_type,
                  plan_period: this.props.period == 0 ? 1 : this.props.period
                },
                callback: d => {
                  if (d.code == 2000) {
                    if (d.data.confirm_url) {
                      window.open(d.data.confirm_url);
                      return;
                    }

                    // 没绑ae
                    if (isGoBindAE && (localStorage.getItem('noMigra') != 'false') && !this.props.userInfo.ae_flag) {
                      this.props.dispatch(routerRedux.push('/bind/ae'));
                      return;
                    }

                    self.location.href = '/app/'; // 首页
                  } else {
                    captureMessage(`[pricing] ${d?.msg || 'guidance/modificationProject'}`);
                  }
                }
              }
            });
          }
        }
      );
    }
  };

  dispatchChangeNew = () => {
    this.props.dispatch({
      type: 'guidance/setProject',
      payload: {
        data: {
          plan_type:7,
          plan_period: 1,
        },
        callback: d => {
          if (d.code == 2000) {
            if (d.data.confirm_url) {
              window.open(d.data.confirm_url);
              return;
            }

            // 没绑ae
            if (isGoBindAE && (localStorage.getItem('noMigra') != 'false') && !this.props.userInfo.ae_flag) {
              this.props.dispatch(routerRedux.push('/bind/ae'));
              return;
            }

            self.location.href = '/app/'; // 首页
          } else {
            captureMessage(`[pricing] ${d?.msg || 'guidance/setProject'}`);
          }
        }
      }
    });
  }

  hideModal = () => {
    this.setState({
      subscribeVisivble:false
    })
  }

  yesHandele = () => {
    if(this.state.flag){
      this.dispatchChangeNew()
    }
    this.setState({
      flag:false
    })

  }

  noHandele = () => {
    if(this.state.flag){
      this.handleConfirm()
    }
    this.setState({
      flag:false
    })
  }

  confimChange = () => {
    // this.props.closePopup()

    if(String(this.props.userInfo.status).substring(2,1) == 0 && this.props.type === 'Standard' && this.props.period == 1){
      this.setState({
        subscribeVisivble:true,
      })
      return
    }else{
      this.handleConfirm()
    }

    window.dtag.set({
      event:'click',
      module:'pricing',
      action:'click_start_free_trial_modal_button',
    })

  }

  confimChange2 = () => {
    window.location.reload()
    window.dtag.set({
      event:'click',
      module:'pricing',
      action:'click_start_free_trial_modal_reload_button',
    })
  }

  render() {
    const { value, stores, buttonDisabled, visible = false,subscribeVisivble } = this.state;
    return (
      <div>
        <FixedModal
          type={1}
          visible={subscribeVisivble}
          hideModal={this.hideModal}
          yesHandele={this.yesHandele}
          noHandele={this.noHandele}
        />
        <Modal
          title={intl.get('pricing.pop_Up')}
          centered
          // closable={false}
          visible
          onCancel={() => this.props.closePopup()}
          footer={
              this.state.refresh ? <Button
              onClick={this.confimChange2}
              type="primary"
            >
              refresh
            </Button> : 
            <Button
              onClick={this.confimChange}
              disabled={buttonDisabled}
              type="primary"
            >
              {intl.get('pricing.Confirm')}
            </Button>
          }
        >
          <Radio.Group
            style={{ width: '10px' }}
            onChange={this.onChoice}
            value={value}
          >
            {stores &&
              stores.map(item => {
                return (
                  <Radio
                    key={item.id}
                    style={{ padding: '5px 0px' }}
                    disabled={item.disabled || item.status == 2}
                    value={item.id}
                  >
                    {item.domain}
                  </Radio>
                );
              })}
          </Radio.Group>
        </Modal>
      </div>
    );
  }
}
