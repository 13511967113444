export const isbindAE = AEInfo => {
  if (AEInfo === undefined) {
    return false;
  }

  if (
    window.DSERS_BIND_AE &&
    (AEInfo === null || AEInfo.status !== 1)
  ) {
    return true;
  }

  return false;
};
